import React, { useEffect } from "react";
import { navigate } from "gatsby";

import { Confirmation } from "../components/common";

import Auth from "../components/auth";
import ConfirmationImage from "../assets/images/confirmations/reset.svg";

function ConfirmToResetPassword() {
  useEffect(() => {
    if (typeof window === "undefined" || !window?.history?.state?.email) {
      navigate("/reset-password");
    }
  });

  return (
    <Auth title="Signup">
      <Confirmation
        subTitle="Email Sent"
        title={
          <>
            Please Check Your Email to
            <br />
            Set New Password
          </>
        }
        description={
          <>
            It will arrive in a moment. Open the email, click on the{" "}
            <strong>Set New Password</strong> button. If you use gmail, please
            find the email in promotions or updates tab. Also, check the spam
            folder if you did not get the email.
          </>
        }
        image={ConfirmationImage}
        instruction="Showing what to click to set a new password"
      />
    </Auth>
  );
}

export default ConfirmToResetPassword;
